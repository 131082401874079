<template>
  <div class="consent-form">
    <div class="h4">
      互联网诊疗知情同意书
    </div>
    <div class="content">
      <div class="wel">
        根据《互联网诊疗管理办法(试行)》、《互联网医院管理办法(试行)》、《远程医疗服务管理办法（试行）》等法规的要求，本知情同意书是您与深圳蓝十字脑科医院互联网医院就医客户端提供的医疗服务所订立的契约，当您接受深圳蓝十字脑科医院互联网医院在线的咨询服务，即表示您已充分阅读、理解并接受本协议的全部内容，本知情同意书即构成对双方有约束力的法律文件。
      </div>
      <div class="line" />
      <div class="h5">
        一、用户接受服务前，知情、同意并确认
      </div>
      <div class="wel">
        1、当用户接受互联网医院的咨询服务，即视为用户确认自己具有享有在线问诊服务、下单购买等相应的权利能力和行为能力，能够独立承担法律责任。
      </div>
      <div class="wel">
        2、如果您在18周岁以下，您只能在父母或监护人的监护参与下才能使用在线问诊服务。
      </div>
      <div class="wel">
        3、蓝生脑医客户端保留在中华人民共和国大陆地区施行法律允许的范围内独自决定拒绝服务、关闭用户账户、取消订单的权利。
      </div>
      <div class="wel">
        4、本互联网医院提供复诊服务，您确认所问诊疾病，已经在实体医疗机构明确诊断，已经提供或上传所能提供的相关病历资料，愿意接受互联网诊疗。本互联网医院不能开具麻醉药品、精神类药品处方，以及其他用药风险较高，有其他特殊管理规定的药品处方。
      </div>
      <div class="wel">
        5、受限于互联网诊疗本身的局限性(如医生不能面诊、触诊等，无法通过相关的诊查手段及检查、检验结果准确判断病情的进展)，医生给出的本次诊疗方案、健康管理方案依赖于患者所上传的资料和描述的症状，以及既往的病历资料、临床诊断。如前述信息不准确或不全面，将对本次诊疗方案的合理制定产生一定的影响。在接受远程医疗服务过程中，请您真实回答医生提出的问题，切勿故意隐瞒或虚报。由此造成的不良后果，一切由用户本人负责。
      </div>
      <div class="wel">
        6、您确认线上就诊时患者个人信息登记完整且保证真实，否则承担由此引发的不良后果。
      </div>
      <div class="wel">
        7、您确认愿意接受医生根据诊疗经验安排的远程诊疗服务。
      </div>
      <div class="wel">
        8、您确认已经知晓以上全部内容，理解相关风险，愿意接受互联网医院的服务以及接受疾病诊疗服务，并同意知情同意书。
      </div>
      <div class="wel">
        9、您确认未得到服务结果会百分之百的许诺。
      </div>
      <div class="line" />
      <div class="h5">
        二、服务条款声明
      </div>
      <div class="wel">
        1、本服务由互联网医院提供，并以线上咨询、部分常见病、慢性病复诊为主，对于初诊、急危重症、婴幼儿、孕产妇等因疾病的特殊性、病情变化快、病史难采集，患者应当到线下医疗机构就诊，银川蓝生平台医生有权拒绝接诊。
      </div>
      <div class="wel">
        2、互联网医院不能直接进行体格检查和实施影像学检查、实验室检验等诊查手段，一旦医生认为患者出现病情变化且需要医务人员亲自诊查时，医生有权终止本次诊疗活动，患者应积极配合到实体医疗机构就诊。您有权选择平台医生的诊疗建议及方案，但方案的最终采纳由您自行或您与医生协商后決定，银川蓝生平台不承担您选择治疗方案而导致的所有法律责任。
      </div>
      <div class="wel">
        3、提供互联网诊疗、远程医疗服务的医生，可能来自于实体医疗机构中的不同级别、性质的医院，执业年限及经验不一。互联网医院的分诊、导诊及医患匹配体系已根据患者的需求尽力匹配合适的医生为患者提供服务。但受限于医生的执业经验和背景，医生给诊疗方案的合理性和先进性可能存在差异。
      </div>
      <div class="wel">
        4、任何药物都有毒副作用，通过口服、外用等任何给药途径，都可能对人体产生毒副作用。患者未按医嘱擅自用药可能危及生命健康，因患者擅自用药所导致的后果，本平台不承担任何责任。
      </div>
      <div class="wel">
        5、平台为用户提供的在线问诊、远程医疗服务。包括电话、图文、视频形式的远程问诊服务。针对电话问诊、视频问诊，平台为保证问诊的服务质量，将对问诊过程进行录音录像。
      </div>
      <div class="wel">
        6、用户有权选择医生的问诊建议及方案，但平台不保证用户要求的诊断、治疗一定能获得满足，不保证问诊结果一定符合用户预期。
      </div>
      <div class="wel">
        7、用户应在预约时段内准时上线问诊，若医生已发起视频，则视为医生已接诊，医生在整个问诊环节，都有可能针对本次服务情况扣除诊费或不扣费。
      </div>
      <div class="wel">
        8、当用户顺利使用完在线问诊服务后不得因为在线问诊服务中的内容不满意要求退款。
      </div>
      <div class="wel">
        9、根据政策要求，目前视频复诊仅针对复诊用户开放，请在选择视频复诊前确认。若因用户在非复诊情况下进行视频复诊而造成的后果，蓝生脑医客户端所有者(浙江蓝生信息科技有限公司)及提供服务的医疗机构和医生不承担任何责任。
      </div>
      <div class="wel">
        10、医生因职业特殊，如医生停诊，则本次问诊取消，请给予理解。
      </div>
      <div class="wel">
        11、在线问诊过程中遇到医生有紧急事情处理时(医生职业要求)，医生暂停或停止问诊，请给予理解。
      </div>
      <div class="wel">
        12、用户在线问诊过程中要语言文明，尊重医生，平等交流。如有对医生恶意中伤，语言不文明，医生有权立即中断服务，此类服务，无权要求退款。
      </div>
      <div class="wel">
        13、作为医生、患者间交流互通平台，用户通过本客户端在线问诊发表的各种言论(包括但不仅限于在线问诊、医生评价等)，并不代表客户端赞同您的观点或证实其内容的真实性。
      </div>
      <div class="wel">
        14、用户应当遵循本知情同意书的约定发表言论、提供信息，对以下几类问题医生有权不予回复:
      </div>
      <div class="wel">
        (1)非医疗健康类问题，如涉黄、涉赌、涉毒、社会意识形态等问题;
      </div>
      <div class="wel">
        (2)胎儿性别鉴定问题;
      </div>
      <div class="wel">
        (3)可能危害他人、自己或社会安全的问题;
      </div>
      <div class="wel">
        (4)渲染悲愤、误导他人的问题;
      </div>
      <div class="wel">
        (5)其它有伤社会风化等其他甲方或医生认为有必要予以禁止的问题;
      </div>
      <div class="wel">
        (6)医疗司法举证或询证问题;
      </div>
      <div class="wel">
        (7)追问医生个人信息;
      </div>
      <div class="wel">
        (8)故意挑逗、侮辱医生的提问。
      </div>
      <div class="wel">
        15、由于疾病本身的特殊性和复杂性，患者本身的体质状况，及现有医疗水平条件的限制等，都存在可能发生各种并发症和危害自身生命健康的意外风险。
      </div>
      <div class="wel">
        16、由于疾病本身的复杂性，以及诊疗措施疗效出现的延后性，诊疗方案、健康管理方案、可能不会达到患者期许的效果，且有些疾病或并发症是不可根治的，需要患者积极配合，长期坚持治疗，才能延缓疾病的进展。医生已经尽力为患者制定合理的方案，致力减少药物治疗不良反应的发生，但不可能完全避免，且不可预测，需要在患者的配合下，并根据临床情况不断调整方案。
      </div>
      <div class="wel">
        17、配送药品可能受到物流时效的影响，导致患者延时服用药物，或患者正在用药物或者手术等治疗其他疾病，也可能存在延时用药、联合用药等风险。
      </div>
      <div class="wel">
        18、问诊过程中，为辅助医生作出问诊建议，医生可查看用户本人的健康档案。
      </div>
      <div class="wel">
        19、本客户端上的服务价格、数量、能否提供等信息随时都有可能发生变动，本客户端不作特别通知。
      </div>
      <div class="wel">
        20、用户对在线问诊服务不满意，可以向互联网医院提出投诉，互联网医院有义务依据情况协调沟通，维护医患关系和谐。
      </div>
      <div class="line" />
      <div class="h5">
        三、法律管辖和适用
      </div>
      <div class="wel">
        本知情同意书的订立、执行和解释及争议的解决均应适用在中华人民共和国大陆地区适用之有效法律(但不包括其冲突法规则)。如发生本知情同意书与适用之法律相抵触时，则这些条款将完全按法律规定重新解释，而其它有效条款继续有效。如缔约方就本知情同意书内容或其执行发生任何争议，双方应尽力友好协商解決;协商不成时，任何一方均有权向企业所在地有管辖权的中华人民共和国大陆地区法院提起诉讼。
      </div>
      <div class="line" />
    </div>
    <div class="sign">
      深圳蓝生脑科医院互联网医院
    </div>
    <div class="sign">
      2023年9月
    </div>
  </div>
</template>

<script>
export default {
  name: "AgreeLetter"
}
</script>

<style scoped lang='less'>
.consent-form {
  padding: 40px;
  color: #000;
  .h4 {
    font-size: 40px;
    font-weight: bold;
    line-height: 46px;
    margin-bottom: 40px;
    text-align: center;
  }

  .content {
    font-size: 32px;
    text-align: justify;
    .h5 {
      font-weight: bold;
      font-size: 36px;
      padding-bottom: 20px;
      padding-top: 20px;
    }

    .wel {
      font-size: 32px;
      line-height: 60px;
      color: #333;
      text-indent: 60px;
    }

    .line {
      height: 40px;
      background: #ffffff;
      width: 100%;
    }

    .sign {
      display: flex;
      justify-content: flex-end;
      font-size: 32px;
      line-height: 60px;
      color: #333;
    }
  }
}
</style>
